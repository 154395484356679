import React from 'react';
import '../../assets/styles/Home.css';


import Logo from '../../assets/images/logo.svg';

const HomeGrid = () => {
  return (

        <div className="grid-container">
            <div className="grid-row">

                <div className="grid-box">        
                    <img src={Logo}  c alt="HomePage"></img>
                </div>
                <div className="grid-box-text">
                    <h2>Todo su trabajo está seguro</h2>
                    <p>Nos tomamos muy en serio la seguridad de sus datos, por ello utilizamos protocolos de cifrado avanzados para proteger que sus archivos en la nube. Tus datos están a salvo y seguros con nosotros.</p>
                    <button className='btn-demo'>Saber más</button>
                </div>
            </div>
            <div className="grid-row">
                <div className="grid-box-text2">
                    <h2>Ahorro de costes de forma inteligente</h2>
                    <p>Potente herramienta que le ayuda a reducir costes y ahorrar dinero en la medición y acciones para mejorar el bienestar  de su empresa. <br></br><br></br>

                    Con análisis avanzados y algoritmos de optimización, The Pulse optimiza sus flujos de trabajo e identifica áreas de mejora.</p>
                    <button className='btn-demo'>Saber más</button>
                </div>
                <div className="grid-box">        
                    <img src={Logo}   alt="HomePage"></img>
                </div>

            </div>

        </div>

  );
};

export default HomeGrid;
