import React from 'react';
import '../../assets/styles/AboutUs.css';


import TeamPhoto from '../../assets/images/team.png';

const teamMembers = [
  { name: 'Hannah Mika', role: 'CEO', image: TeamPhoto },
  { name: 'Daniel Peter', role: 'CTO', image: TeamPhoto },
  { name: 'Laura Miskel', role: 'Head of Operations', image: TeamPhoto},
  { name: 'Denis Forner', role: 'Head of Operations', image: TeamPhoto},
  { name: 'Edward Finch', role: 'Head of Development', image: TeamPhoto },
  { name: 'Matthew Low ', role: 'Tech Support', image: TeamPhoto},
  { name: 'Lucas Bell', role: 'Key Account Manager', image: TeamPhoto},
  { name: 'Michaela Hans', role: 'Head of Operations', image: TeamPhoto },

];

const TeamMember = ({ member }) => {
  return (
    <div className="team-member">
      <img src={member.image} alt={member.name} />
      <h3>{member.name}</h3>
      <p>{member.role}</p>
    </div>
  );
};

const AboutUs_2 = () => {
  return (
    <div className="team-container">
      <h2>Team</h2>
      <p>Meet the people behind our magical product</p>
      <div className="team-members">
        {teamMembers.map(member => (
          <TeamMember key={member.name} member={member} />
        ))}
      </div>
    </div>
  );
};

export default AboutUs_2;
