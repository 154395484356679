import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faApple, faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { Link, useNavigate } from 'react-router-dom'; // Asegúrate de importar useNavigate solo una vez
import '../../assets/styles/Login.css';

import Logo from '../../assets/images/logo.svg';

const LogIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('http://localhost:3001/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('token', data.token);
        // Dispara el evento personalizado
        window.dispatchEvent(new Event('authChange'));
        navigate('/dashboard');

      } else {
        // Manejar errores de inicio de sesión aquí
        console.log('Error de inicio de sesión', data.message);
      }
    } catch (error) {
      console.error('Error de conexión:', error);
    }
  };



  return (
    <div className="common-container">
      <div className="logo-background">
        <img src={Logo} alt="Logo" className="logo" />
      </div>
      <div className="login-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <h2>Iniciar sesión</h2>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Correo electrónico"
            required
          />
          <div className="password-field">
            <input
              type={isPasswordVisible ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              placeholder="Contraseña"
              required
            />
            <FontAwesomeIcon
              icon={isPasswordVisible ? faEyeSlash : faEye}
              onClick={togglePasswordVisibility}
              className="password-icon"
            />
          </div>
          <div className="forgot-password">
            <span>¿Olvidaste tu contraseña?</span>
            <Link to="/reset-password" className="link-underline"> Restablece tu contraseña</Link>
          </div>
          <button type="submit" className="login-button" >Iniciar</button>
          <div className="divider">o</div>
          <button type="button" className="google-button">
            <FontAwesomeIcon icon={faGoogle} /> Continuar con Google
          </button>
          <button type="button" className="microsoft-button">
            <FontAwesomeIcon icon={faMicrosoft} /> Continuar con Microsoft
          </button>
          <button type="button" className="apple-button">
            <FontAwesomeIcon icon={faApple} /> Continuar con Apple
          </button>
          <div className="register-link ">
            <span>¿No tienes una cuenta?</span>
            <Link to="/sign-up" className="link-underline"> Regístrate</Link>
          </div>
        </form>
      </div>
      
    </div>
   
  );
};

export default LogIn;
